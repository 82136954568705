import { IconProps } from "icon";

export const CardIcon = ({ size = 16 }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.513 13.1061H19.2695V11.9394H14.513V13.1061ZM14.513 9.87439H19.2695V8.70772H14.513V9.87439ZM4.7305 16.4264H12.4492V16.2327C12.4492 15.5522 12.1069 15.0252 11.4225 14.6519C10.7381 14.2786 9.79383 14.0919 8.58983 14.0919C7.38583 14.0919 6.44122 14.2786 5.756 14.6519C5.07078 15.0252 4.72894 15.5522 4.7305 16.2327V16.4264ZM8.58983 12.2077C9.08217 12.2077 9.49672 12.0393 9.8335 11.7026C10.1711 11.365 10.3398 10.9501 10.3398 10.4577C10.3398 9.96539 10.1711 9.55084 9.8335 9.21406C9.49594 8.87728 9.08139 8.7085 8.58983 8.70772C8.09828 8.70695 7.68372 8.87572 7.34617 9.21406C7.00861 9.55239 6.83983 9.96695 6.83983 10.4577C6.83983 10.9485 7.00861 11.3634 7.34617 11.7026C7.68372 12.0417 8.09828 12.2101 8.58983 12.2077ZM3.38533 20.7337C2.84789 20.7337 2.3995 20.5541 2.04017 20.1947C1.68083 19.8354 1.50078 19.3866 1.5 18.8484V6.28572C1.5 5.74828 1.68006 5.29989 2.04017 4.94056C2.40028 4.58122 2.84828 4.40117 3.38417 4.40039H20.6158C21.1525 4.40039 21.6005 4.58045 21.9598 4.94056C22.3192 5.30067 22.4992 5.74906 22.5 6.28572V18.8496C22.5 19.3862 22.3199 19.8346 21.9598 20.1947C21.5997 20.5548 21.1517 20.7345 20.6158 20.7337H3.38533ZM3.38533 19.5671H20.6158C20.7947 19.5671 20.9592 19.4924 21.1093 19.3431C21.2594 19.1937 21.3341 19.0288 21.3333 18.8484V6.28572C21.3333 6.10606 21.2587 5.94117 21.1093 5.79106C20.96 5.64095 20.7955 5.56628 20.6158 5.56706H3.38417C3.20528 5.56706 3.04078 5.64172 2.89067 5.79106C2.74056 5.94039 2.66589 6.10528 2.66667 6.28572V18.8496C2.66667 19.0284 2.74133 19.1929 2.89067 19.3431C3.04 19.4932 3.2045 19.5678 3.38417 19.5671"
      fill="#003642"
    />
  </svg>
);
