interface IconProps {
  size?: number;
}

export const MainInfoIcon = ({ size = 16 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66602 1.33334V4.00001"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.33301 1.33334V4.00001"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 1.33334V4.00001"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3333 2.66666H4.33333C3.59695 2.66666 3 3.26361 3 3.99999V13.3333C3 14.0697 3.59695 14.6667 4.33333 14.6667H12.3333C13.0697 14.6667 13.6667 14.0697 13.6667 13.3333V3.99999C13.6667 3.26361 13.0697 2.66666 12.3333 2.66666Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66602 6.66669H9.66602"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66602 9.33331H10.9993"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66602 12H8.99935"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
