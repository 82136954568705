import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import LayoutWithMenu from "components/LayoutWithMenu";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SettingSideBar } from "./SettingSideBar";

export function FacilityReservationSetting(): React.ReactElement {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;

  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate(); // 月の日数を取得
  };

  const getStartDayOfWeek = (year: number, month: number) => {
    return new Date(year, month - 1, 1).getDay(); // 月の初日の曜日 (0=日曜, 1=月曜, ...)
  };

  // 年と月のステートを管理
  const [year, setYear] = useState<number>(currentYear);
  const [month, setMonth] = useState<number>(currentMonth);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setYear(Number(event.target.value));
  };

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setMonth(Number(event.target.value));
  };

  const handlePrevMonth = () => {
    if (month === 1) {
      setYear(year - 1);
      setMonth(12);
    } else {
      setMonth(month - 1);
    }
  };

  const handleNextMonth = () => {
    if (month === 12) {
      setYear(year + 1);
      setMonth(1);
    } else {
      setMonth(month + 1);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const daysInMonth = getDaysInMonth(year, month); // 月の日数
  const startDayOfWeek = getStartDayOfWeek(year, month); // 月初日の曜日
  const weekDays = ["日", "月", "火", "水", "木", "金", "土"]; // 曜日
  const disabledDays = [1, 3, 15, 22, 29]; // 休みの日付
  const priorityDays = [17, 24, 31]; // 優先設定日付

  // 日付を表示するための配列を生成
  const daysArray = Array.from(
    { length: startDayOfWeek + daysInMonth },
    (_, index) => {
      const day = index - startDayOfWeek + 1;
      return day > 0 ? day : null; // 月の日数外は null をセット
    },
  );

  return (
    <LayoutWithMenu fullWidth>
      <Box
        sx={{
          paddingTop: 4,
          mx: {
            sm: 4,
            md: 10,
          },
        }}
      >
        <Typography fontWeight="bold" fontSize="20px" marginBottom="16px">
          予約受付設定
        </Typography>
        {/* 年月選択と前後ボタン */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 2,
            gap: 1,
          }}
        >
          <IconButton onClick={handlePrevMonth}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>年</InputLabel>
            <Select
              value={year}
              onChange={handleYearChange}
              label="年"
              sx={{ fontSize: "20px", bgcolor: "white" }}
            >
              {/* 5年分の選択肢を表示 */}
              {Array.from({ length: 5 }, (_, i) => currentYear - 1 + i).map(
                (y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>月</InputLabel>
            <Select
              value={month}
              onChange={handleMonthChange}
              label="月"
              sx={{ fontSize: "20px", bgcolor: "white" }}
            >
              {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton onClick={handleNextMonth}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        {/* カレンダー表示 */}
        <Box sx={{ border: "1px solid #ccc", padding: 2 }}>
          {/* 曜日ヘッダー */}
          <Grid
            container
            spacing={1}
            sx={{
              borderBottom: "1px solid #ccc",
              paddingBottom: 1,
              marginBottom: 1,
              position: "sticky",
              top: 100,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            {weekDays.map((day, index) => (
              <Grid item xs={1.71} key={index}>
                <Typography textAlign="center" fontWeight="bold">
                  {day}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {/* 日付ボタン */}
          <Grid container spacing={1}>
            {daysArray.map((day, index) => (
              <Grid item xs={1.71} key={index}>
                {day ? (
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={disabledDays.includes(day)}
                    sx={{
                      height: "180px",
                      backgroundColor: disabledDays.includes(day)
                        ? "#f0f0f0"
                        : priorityDays.includes(day)
                          ? "#e0f7fa"
                          : "white",
                      color: disabledDays.includes(day) ? "#999" : "black",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #cccccc",
                    }}
                  >
                    <Typography variant="caption">{day}</Typography>
                    <Stack
                      sx={{
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5">休</Typography>
                    </Stack>
                  </Button>
                ) : (
                  <Box sx={{ height: "100px" }} /> // 空白セル
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* サイドバー表示ボタン */}
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 150,
          }}
        >
          <IconButton
            onClick={toggleSidebar}
            sx={{
              bgcolor: "#334249",
              borderRadius: "0px",
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
              width: "40px",
              height: "80px",
            }}
          >
            <ArrowBackIcon
              sx={{
                color: "#ffffff",
              }}
            />
          </IconButton>
        </Box>
        {/* サイドバー */}
        <SettingSideBar
          anchor="right"
          open={isSidebarOpen}
          onClose={toggleSidebar}
          sx={{ "& .MuiDrawer-paper": { width: "800px" } }}
        />
      </Box>
    </LayoutWithMenu>
  );
}
