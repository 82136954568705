import { Card, CardContent, Typography } from "@mui/material";
import LayoutWithMenu from "components/LayoutWithMenu";
import { styles } from "components/style/commonStyles";
import { useAuthFacilityStaffContext } from "contexts/AuthFacilityStaffContext";
import {
  showClinicReservation,
  showPharmacyReservation,
} from "lib/api/reservation";
import { getUserBloodPressureData } from "lib/api/user";
import { formatDateString } from "lib/date";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BloodPressureTable } from "./BloodPressureTable";

export interface BloodPressureData {
  // 測定日時
  measurementDateTime: string;
  // 脈拍 (bpm)
  pulseRate: number;
  // 最高血圧 (mmHg)
  maxBloodPressure: number;
  // 最低血圧 (mmHg)
  minBloodPressure: number;
}

export interface ReserveInfo {
  // 予約ユーザー名
  name: string;
  // 予約日時
  reserveDate: Date;
}

// FIXME: API繋ぎこみ
const RESERVE_INFO: ReserveInfo = {
  name: "テスト患者ユーザー",
  reserveDate: new Date("2025-02-12T10:30:00"),
};

export function ReservedUserBloodPressure(): React.ReactElement {
  const { reservation_id } = useParams();
  const location = useLocation();
  const isClinicReservation = location.pathname.includes("clinic");
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;

  const [data, setData] = useState<BloodPressureData[]>([]);

  useEffect(() => {
    fetchBloodPressureData();
  }, [authFacilityStaff]);

  // 初期表示
  async function fetchBloodPressureData() {
    if (!authFacilityStaff || !reservation_id) {
      return;
    }

    try {
      let reservation;
      // 医療機関か薬局かをパスから判定し、APIを叩き分ける
      if (isClinicReservation) {
        reservation = await showClinicReservation(reservation_id, {
          facility_id: authFacilityStaff.facilityId,
        });
      } else {
        reservation = await showPharmacyReservation(reservation_id, {
          facility_id: authFacilityStaff.facilityId,
        });
      }
      // 取得した薬局予約情報からユーザーIDを取得し、血圧データ取得のクエリに投げる
      const response = await getUserBloodPressureData(
        reservation.data.userId.toString(),
      );
      const data =
        response.data.bloodPressures?.map((row: any) => ({
          measurementDateTime: formatDateString(row.measureAt),
          pulseRate: row.pulse,
          maxBloodPressure: row.maxValue,
          minBloodPressure: row.minValue,
        })) ?? [];
      setData(data);
    } catch (error) {
      console.error("血圧データの取得に失敗しました", error);
    }
  }

  return (
    <LayoutWithMenu mainContentsMaxWidth="lg">
      <div style={{ marginBottom: "40px" }}>
        <Typography
          sx={{
            ...styles.TypographyPageTitle,
            mt: 8,
            textAlign: "left",
          }}
        >
          血圧データ
        </Typography>
        <Typography
          sx={{
            ...styles.TypographyPageTitle,
            textAlign: "left",
            marginTop: 0,
            paddingBottom: 0,
          }}
        >
          {RESERVE_INFO.name}
        </Typography>
        <p>予約日時：{RESERVE_INFO.reserveDate.toLocaleString()}</p>
      </div>
      <Card
        sx={{
          border: "1px solid #E5E7EB",
          boxShadow: "none",
          borderRadius: "8px",
        }}
      >
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <BloodPressureTable data={data} />
        </CardContent>
      </Card>
    </LayoutWithMenu>
  );
}
