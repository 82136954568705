import React from "react";
import {
  Box,
  IconButton,
  SxProps,
  Alert,
  Typography,
  TextField,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTabPanel } from "./CustomTabPanel";

interface BasicSettingPanelProps {
  value: number;
  index: number;
  sx?: SxProps;
}

export function BasicSettingPanel(
  props: BasicSettingPanelProps,
): React.ReactElement {
  const { value, index, sx } = props;
  return (
    <CustomTabPanel value={value} index={index} sx={sx}>
      <Alert severity="info" sx={{ mb: 3 }}>
        <Typography fontSize="14px">
          各施設の予約に関する基本設定を行います
        </Typography>
      </Alert>
      <Stack sx={{ mb: 3 }} direction={"row"} alignItems={"center"} gap={8}>
        <Typography
          fontSize="14px"
          fontWeight="bold"
          textAlign={"left"}
          width={"100px"}
        >
          予約可能期間
        </Typography>
        <TextField select defaultValue="6ヶ月先まで" size="small">
          <MenuItem value="6ヶ月先まで">6ヶ月先まで</MenuItem>
          <MenuItem value="1年先まで">1年先まで</MenuItem>
        </TextField>
      </Stack>
      <Stack sx={{ mb: 3 }} direction={"row"} alignItems={"center"} gap={8}>
        <Typography
          fontSize="14px"
          fontWeight="bold"
          textAlign={"left"}
          width={"100px"}
        >
          予約単位
        </Typography>
        <TextField select defaultValue="30分間" size="small">
          <MenuItem value="5分間">5分間</MenuItem>
          <MenuItem value="10分間">10分間</MenuItem>
          <MenuItem value="15分間">15分間</MenuItem>
          <MenuItem value="20分間">20分間</MenuItem>
          <MenuItem value="30分間">30分間</MenuItem>
        </TextField>
      </Stack>
      <Typography fontSize="14px" fontWeight="bold" sx={{ mb: 2 }}>
        営業設定
      </Typography>
      <Box sx={{ mb: 3, ml: 3 }}>
        {["月曜", "火曜", "水曜", "木曜", "金曜", "土曜", "日曜", "祝日"].map(
          (day, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mb: 2,
                pb: 2,
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked={index < 5} color="info" />}
                label={day}
                sx={{ minWidth: "80px" }}
              />
              <Stack gap={1.5}>
                {index % 2 === 0 && (
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <TextField
                      type="time"
                      defaultValue="09:00"
                      sx={{ width: "120px" }}
                      label="開始"
                      size="small"
                    />
                    <Typography>〜</Typography>
                    <TextField
                      type="time"
                      defaultValue="12:00"
                      sx={{ width: "120px" }}
                      label="終了"
                      size="small"
                    />
                    <TextField
                      select
                      defaultValue="4枠"
                      sx={{ width: "160px" }}
                      label="予約枠数"
                      size="small"
                    >
                      <MenuItem value="4枠">4枠</MenuItem>
                      <MenuItem value="5枠">5枠</MenuItem>
                    </TextField>
                    <IconButton
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                )}
                <Button variant="outlined" color="info" fullWidth>
                  ＋追加
                </Button>
              </Stack>
            </Box>
          ),
        )}
      </Box>
      <Button variant="contained" color="info" fullWidth>
        反映する
      </Button>
    </CustomTabPanel>
  );
}
