import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthAdminUserContext } from "../../contexts/AuthAdminUserContext";
import { getClinicDetail } from "../../lib/api/clinic";
import { getPharmacyDetail } from "../../lib/api/pharmacy";
import { FacilityType } from "../../utils/Constants";
import { capitalize } from "../../utils/helper";
import { AdminFacilityFormData } from "../schema/FacilitySchema";
import FacilityDetailAdminContent from "./FacilityDetailAdminContent";

const FacilityDetail = () => {
  const navigate = useNavigate();

  const authUser = useAuthAdminUserContext().authAdminUser;
  const searchParams = new URLSearchParams(window.location.search);
  const facilityId = searchParams.get("facility_id") ?? "";
  const facilityType = capitalize(searchParams.get("facility_type") ?? "");

  const [facilityData, setFacilityData] = useState<AdminFacilityFormData>(
    new AdminFacilityFormData(),
  );

  // 初期表示処理
  useEffect(() => {
    if (facilityId !== "") {
      switch (facilityType) {
        case FacilityType.clinic:
          getClinicData();
          return;
        case FacilityType.pharmacy:
          getPharmacyData();
          return;
        default:
          console.log(`未定義の施設タイプ ${facilityType}`);
          return;
      }
    } else {
      navigate("/admin/clinic/list");
    }
  }, []);

  // 施設情報（クリニック）取得
  const getClinicData = async () => {
    try {
      const response = await getClinicDetail(facilityId);
      setFacilityData(response.data);
    } catch (error) {
      console.error("クリニック情報の取得に失敗しました", error);
    }
  };

  // 施設情報（薬局）取得
  const getPharmacyData = async () => {
    try {
      const response = await getPharmacyDetail(facilityId);
      setFacilityData(response.data);
    } catch (error) {
      console.error("薬局情報の取得に失敗しました", error);
    }
  };

  // 編集画面への遷移
  const moveToEdit = () => {
    navigate(
      `/admin/facility/edit?facility_id=${facilityId}&facility_type=${facilityType}`,
    );
  };

  return (
    <FacilityDetailAdminContent
      facilityData={facilityData}
      moveToEdit={moveToEdit}
      editable
      adminAuth={authUser}
      facilityType={facilityType}
    />
  );
};

export default FacilityDetail;
