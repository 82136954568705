import React from "react";
import {
  Box,
  SxProps,
  Alert,
  Typography,
  Button,
  Stack,
  IconButton,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CustomTabPanel } from "./CustomTabPanel";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker } from "@mui/x-date-pickers";

interface FirstPreferenceSettingsPanelProps {
  value: number;
  index: number;
  sx?: SxProps;
}

export function FirstPreferenceSettingsPanel(
  props: FirstPreferenceSettingsPanelProps,
): React.ReactElement {
  const { value, index, sx } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomTabPanel value={value} index={index} sx={sx}>
      <Alert severity="info" sx={{ mb: 3 }}>
        <Typography fontSize="14px">
          特定の日付に対して、基本設定を上書きすることができます。
        </Typography>
        <Typography fontSize="14px">
          なお、優先設定①②が重複する日付に対しては、①が優先されます。
        </Typography>
      </Alert>
      <Stack sx={{ mb: 3 }} direction={"row"} alignItems={"center"} gap={8}>
        <Typography fontSize="14px" fontWeight="bold" textAlign={"left"}>
          特定の日付に対する優先設定
        </Typography>
        <Button variant="contained" color="info" onClick={handleClickOpen}>
          ＋日付を追加する
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"lg"}
          PaperProps={{
            component: "form",
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
              const email = formJson.email;
              console.log(email);
              handleClose();
            },
          }}
        >
          <DialogTitle>日付設定を追加する</DialogTitle>
          <DialogContent>
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
                py: 1,
              }}
            >
              <Stack alignItems={"center"} direction={"row"} gap={2} justifyContent={"start"} width={"100%"}>
                <Typography width={100} fontSize={"16px"}>
                  日付
                </Typography>
                <DatePicker label="日付" />
              </Stack>
              <Stack alignItems={"center"} gap={2} direction={"row"}>
                <Typography width={100} fontSize={"16px"}>
                  営業時間
                </Typography>
                <Stack gap={1.5}>
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <TextField
                      type="time"
                      defaultValue="09:00"
                      sx={{ width: "120px" }}
                      label="開始"
                      size="small"
                    />
                    <Typography>〜</Typography>
                    <TextField
                      type="time"
                      defaultValue="12:00"
                      sx={{ width: "120px" }}
                      label="終了"
                      size="small"
                    />
                    <TextField
                      select
                      defaultValue="4枠"
                      sx={{ width: "160px" }}
                      label="予約枠数"
                      size="small"
                    >
                      <MenuItem value="4枠">4枠</MenuItem>
                      <MenuItem value="5枠">5枠</MenuItem>
                    </TextField>
                    <IconButton
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                  <Button
                    variant="outlined"
                    color="info"
                    fullWidth
                    size="small"
                  >
                    ＋追加
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
            }}
          >
            <Button variant="outlined" onClick={handleClose} color="error">
              キャンセル
            </Button>
            <Button type="submit" color="info">
              追加する
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
      <Typography fontSize="14px" fontWeight="bold" textAlign={"left"} mb={2}>
        既に設定されている優先設定
      </Typography>
      <Stack sx={{ mb: 3 }} direction={"row"} alignItems={"center"} gap={8}>
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 2,
            pb: 2,
          }}
        >
          <Stack gap={1.5}>
            <Stack
              direction={"row"}
              gap={2}
              alignItems={"center"}
              bgcolor={"#F5FAFD"}
              p={2}
              borderRadius={2}
            >
              <Typography fontWeight={"bold"}>YYYY/MM/DD（D）</Typography>
              <TextField
                type="time"
                defaultValue="09:00"
                sx={{ width: "120px", backgroundColor: "white" }}
                label="開始"
                disabled
              />
              <Typography>〜</Typography>
              <TextField
                type="time"
                defaultValue="12:00"
                sx={{ width: "120px", backgroundColor: "white" }}
                label="終了"
                disabled
              />
              <TextField
                select
                defaultValue="4枠"
                sx={{ width: "160px", backgroundColor: "white" }}
                label="予約枠数"
                disabled
              >
                <MenuItem value="4枠">4枠</MenuItem>
                <MenuItem value="5枠">5枠</MenuItem>
              </TextField>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <IconButton
                  sx={{
                    width: "32px",
                    height: "32px",
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{
                    width: "32px",
                    height: "32px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Button variant="contained" color="info" fullWidth>
        反映する
      </Button>
    </CustomTabPanel>
  );
}
