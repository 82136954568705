import { IconProps } from "icon";

export const FormIcon = ({ size = 16 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.41667 7H6.5"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.41667 4.66666H6.5"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.75 9.91667V2.91667C11.75 2.60725 11.6271 2.3105 11.4083 2.09171C11.1895 1.87292 10.8928 1.75 10.5833 1.75H3"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33398 12.25H12.334C12.6434 12.25 12.9402 12.1271 13.1589 11.9083C13.3777 11.6895 13.5007 11.3928 13.5007 11.0833V10.5C13.5007 10.3453 13.4392 10.1969 13.3298 10.0875C13.2204 9.97812 13.072 9.91667 12.9173 9.91667H7.08398C6.92928 9.91667 6.7809 9.97812 6.67151 10.0875C6.56211 10.1969 6.50065 10.3453 6.50065 10.5V11.0833C6.50065 11.3928 6.37773 11.6895 6.15894 11.9083C5.94015 12.1271 5.6434 12.25 5.33398 12.25ZM5.33398 12.25C5.02457 12.25 4.72782 12.1271 4.50903 11.9083C4.29023 11.6895 4.16732 11.3928 4.16732 11.0833V2.91667C4.16732 2.60725 4.0444 2.3105 3.82561 2.09171C3.60682 1.87292 3.31007 1.75 3.00065 1.75C2.69123 1.75 2.39449 1.87292 2.17569 2.09171C1.9569 2.3105 1.83398 2.60725 1.83398 2.91667V4.08333C1.83398 4.23804 1.89544 4.38642 2.00484 4.49581C2.11423 4.60521 2.26261 4.66667 2.41732 4.66667H4.16732"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
