import React from "react";
import {
  Box,
  SxProps,
  Alert,
  Typography,
  Button,
  Stack,
  IconButton,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { CustomTabPanel } from "./CustomTabPanel";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";

interface SecondPreferenceSettingsPanelProps {
  value: number;
  index: number;
  sx?: SxProps;
}

export function SecondPreferenceSettingsPanel(
  props: SecondPreferenceSettingsPanelProps,
): React.ReactElement {
  const { value, index, sx } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomTabPanel value={value} index={index} sx={sx}>
      <Alert severity="info" sx={{ mb: 3 }}>
        <Typography fontSize="14px">
          特定の日付に対して、基本設定を上書きすることができます。
        </Typography>
        <Typography fontSize="14px">
          なお、優先設定①②が重複する日付に対しては、①が優先されます。
        </Typography>
      </Alert>
      <Stack sx={{ mb: 3 }} direction={"row"} alignItems={"center"} gap={8}>
        <Typography fontSize="14px" fontWeight="bold" textAlign={"left"}>
          特定の期間に対する優先設定
        </Typography>
        <Button variant="contained" color="info" onClick={handleClickOpen}>
          ＋日付を追加する
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"lg"}
          PaperProps={{
            component: "form",
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
              const email = formJson.email;
              console.log(email);
              handleClose();
            },
          }}
        >
          <DialogTitle>日付設定を追加する</DialogTitle>
          <DialogContent>
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
                py: 1,
              }}
            >
              <Stack
                alignItems={"center"}
                direction={"row"}
                gap={2}
                justifyContent={"start"}
                width={"100%"}
              >
                <Typography width={100} fontSize={"16px"}>
                  日付
                </Typography>
                <DatePicker label="日付" />
                <Typography fontSize="16px" sx={{ textAlign: "left" }}>
                  ~
                </Typography>
                <DatePicker label="日付" />
              </Stack>
              <Stack direction={"row"} gap={2}>
                <Typography fontSize="16px" sx={{ textAlign: "left" }}>
                  営業設定
                </Typography>

                <Box sx={{ mb: 3, ml: 3 }}>
                  {[
                    "月曜",
                    "火曜",
                    "水曜",
                    "木曜",
                    "金曜",
                    "土曜",
                    "日曜",
                    "祝日",
                  ].map((day, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 2,
                        pb: 2,
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox defaultChecked={index < 5} color="info" />
                        }
                        label={day}
                        sx={{ minWidth: "80px" }}
                      />
                      <Stack gap={1.5}>
                        {index % 2 === 0 && (
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                          >
                            <TextField
                              type="time"
                              defaultValue="09:00"
                              sx={{ width: "120px" }}
                              label="開始"
                              size="small"
                            />
                            <Typography>〜</Typography>
                            <TextField
                              type="time"
                              defaultValue="12:00"
                              sx={{ width: "120px" }}
                              label="終了"
                              size="small"
                            />
                            <TextField
                              select
                              defaultValue="4枠"
                              sx={{ width: "160px" }}
                              label="予約枠数"
                              size="small"
                            >
                              <MenuItem value="4枠">4枠</MenuItem>
                              <MenuItem value="5枠">5枠</MenuItem>
                            </TextField>
                            <IconButton
                              sx={{
                                width: "32px",
                                height: "32px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        )}
                        <Button variant="outlined" color="info" fullWidth>
                          ＋追加
                        </Button>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
            }}
          >
            <Button variant="outlined" onClick={handleClose} color="error">
              キャンセル
            </Button>
            <Button type="submit" color="info">
              追加する
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
      <Typography fontSize="14px" fontWeight="bold" textAlign={"left"} mb={2}>
        既に設定されている優先設定
      </Typography>
      <Stack
        sx={{ mb: 3 }}
        direction={"row"}
        alignItems={"center"}
        gap={8}
        width={"100%"}
      >
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 2,
            mb: 2,
            pb: 2,
            width: "100%",
          }}
        >
          <Stack
            gap={2}
            bgcolor={"#F5FAFD"}
            p={2}
            borderRadius={2}
            width={"100%"}
          >
            <Typography fontWeight={"bold"}>
              2024/12/28 (土) 〜 2025/1/3 (金)
            </Typography>
            <Box sx={{ ml: 3 }}>
              {[
                "月曜",
                "火曜",
                "水曜",
                "木曜",
                "金曜",
                "土曜",
                "日曜",
                "祝日",
              ].map((day, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 1,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked={index < 5} color="info" />
                    }
                    label={day}
                    sx={{ minWidth: "80px" }}
                  />
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Button variant="contained" color="info" fullWidth>
        反映する
      </Button>
    </CustomTabPanel>
  );
}
