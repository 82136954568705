import styled from "@emotion/styled";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { BloodPressureData } from "./ReservedUserBloodPressure";

interface BloodPressureTableProps {
  data: BloodPressureData[];
}

const TableRowStyled = styled(TableRow)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const TableHeaderCell = styled(TableCell)`
  padding: 16px 8px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
`;

export function BloodPressureTable(
  props: BloodPressureTableProps,
): React.ReactElement {
  const { data: bloodPressureDataList } = props;

  return (
    <Table>
      <TableHead>
        <TableRowStyled sx={{ borderBottom: "1px solid #ccc" }}>
          <TableHeaderCell>測定日時</TableHeaderCell>
          <TableHeaderCell>脈拍(bpm)</TableHeaderCell>
          <TableHeaderCell>最高血圧(mmHg)</TableHeaderCell>
          <TableHeaderCell>最低血圧(mmHg)</TableHeaderCell>
        </TableRowStyled>
      </TableHead>
      <TableBody>
        {bloodPressureDataList.map((bloodPressureData, index) => (
          <BloodPressureTableRow key={index} data={bloodPressureData} />
        ))}
      </TableBody>
    </Table>
  );
}

interface BloodPressureTableRowProps {
  data: BloodPressureData;
}

const TableCellStyled = styled(TableCell)`
  padding: 16px 8px;
  font-size: 13px;
  text-align: center;
`;

function BloodPressureTableRow(
  props: BloodPressureTableRowProps,
): React.ReactElement {
  const { data } = props;

  return (
    <TableRowStyled key={data.measurementDateTime}>
      <TableCellStyled>{data.measurementDateTime}</TableCellStyled>
      <TableCellStyled>{data.pulseRate}</TableCellStyled>
      <TableCellStyled>{data.maxBloodPressure}</TableCellStyled>
      <TableCellStyled>{data.minBloodPressure}</TableCellStyled>
    </TableRowStyled>
  );
}
