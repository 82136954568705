import {
  CalendarToday as CalenderTodayIcon,
  AccessTime as ClockIcon,
  LocationOn as LocationIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material";
import {
  AlertColor,
  Box,
  Button,
  Chip,
  Container,
  FormHelperText,
  Grid,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getClinicDetail } from "../../lib/api/clinic";
import { getPaymentByReservation } from "../../lib/api/payment";
import {
  createMeeting,
  getClinicReservationRelation,
  getMeetingByReservationId,
  showClinicReservation,
  updateReservationStatus,
} from "../../lib/api/reservation";
import { getUserDetail } from "../../lib/api/user";

import { PaymentData } from "../../components/schema/PaymentSchema";
import { RelateReservationData } from "../../components/schema/ReservationSchema";

import LayoutWithMenu from "../LayoutWithMenu";
import { Snackbar } from "../parts/Snackbar";
import { styles as commonStyles } from "../style/commonStyles";

import {
  ClinicReservationRelation,
  FacilityReservation,
  User,
} from "../../interfaces";

import { formattedDatetime } from "utils/helper";
import { ButtonCaption, ReservationStatus } from "../../utils/Constants";

import { useAuthFacilityStaffContext } from "../../contexts/AuthFacilityStaffContext";
import client from "../../lib/api/client";

import {
  LocalVideo,
  RemoteVideo,
  VideoGrid,
  lightTheme,
  useLocalVideo,
  useMeetingManager,
  useMeetingStatus,
  useRemoteVideoTileState,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { ThemeProvider } from "styled-components";

import { MeetingVideoControlBox } from "components/atoms/MeetingVideoControlBox";
import dayjs from "dayjs";
import { BloodPressureIcon } from "icon/bloodPressure";
import { CardIcon } from "icon/card";
import { FormIcon } from "icon/form";
import { MainInfoIcon } from "icon/mainInfo";
import { NotebookIcon } from "icon/notebook";
import { PrescriptionIcon } from "icon/prescription";
import { Response$get$MeetingByReservationId } from "types/schemas";
import { zipAndAddress } from "utils/stringHelper";

const styles = {
  ...commonStyles,
  GridContainer: {
    borderBottom: 1,
    borderColor: "lightGrey",
    py: 1,
    fontSize: 16,
    textDecoration: "none",
    color: "#000000",
    paddingTop: 5,
    paddingBottom: 5,
  },
  gridItem: {
    alignContent: "center",
    backgroundColor: "#F5FAFD !important",
    display: "flex", // アイテムを中央揃えにするために追加
    alignItems: "center", // アイテムを中央揃えにするために追加
    paddingLeft: "0 !important",
    paddingBottom: "16px",
  },
  Button: {
    backgroundColor: "#21bddb",
    boxShadow: "none",
    fontWeight: 600,
    width: "100%",
    "&:hover": {
      backgroundColor: "#1aa3c9",
      boxShadow: "none",
    },
  },
  Box: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "16px",
    marginBottom: "16px",
  },
  Title: {
    fontWeight: "bold",
    marginBottom: "8px",
  },
  ButtonContainer: {
    display: "grid",
    justifyContent: "center",
    marginTop: "16px",
  },
  Divider: {
    borderTop: "1px solid #ddd",
    margin: "16px 0",
  },
  RightAlign: {
    textAlign: "right",
  },
  GreenButton: {
    backgroundColor: "#4CAF50",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  amountInput: {
    width: "100px",
  },
  chipInfo: {
    p: "10px",
    borderRadius: "8px",
    backgroundColor: "#87D1EA",
    color: "#003642",
  },
  chipWarn: {
    p: "10px",
    borderRadius: "8px",
    backgroundColor: "#C1C4EB",
    color: "#003642",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "0px",
    color: "#32AFDA",
    paddingLeft: "5px",
  },
  boldLabel: {
    fontWeight: 700, // 項目名を太字にする
  },
};

const ClinicReservationDetail = () => {
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;
  const { reservation_id } = useParams();
  const [reservation, setReservation] = useState<FacilityReservation>();
  const [relationReservations, setRelationReservations] = useState<
    RelateReservationData[]
  >([]);
  const [payment, setPayment] = useState<PaymentData>(new PaymentData());
  const [user, setUser] = useState<User>();

  const [hasFincodeShopId, setHasFincodeShopId] = useState<boolean>(false);

  const [payAmount, setPayAmount] = useState<number>(0);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const [canStartExamination, setCanStartExamination] =
    useState<boolean>(false);
  const [reserveMessage, setReserveMessage] = useState<string>("");

  const navigation = useNavigate();

  const facilityName = (name: string, name_short: string) => name_short || name;

  const [isMeetingConnected, setIsMeetingConnected] = useState<boolean>(false);
  const [isLoadingConnect, setIsLoadingConnect] = useState<boolean>(false);
  const [isLoadingFetch, setIsLoadingFetch] = useState<boolean>(false);

  // Amazon Chimeへの接続処理
  const meetingManager = useMeetingManager();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [videoGridWidth, setVideoGridWidth] = useState("10vw"); // 初期値
  const [videoGridHeight, setVideoGridHeight] = useState("30vw"); // 初期値
  const videoGridRef = useRef<HTMLDivElement>(null);

  const handleDeviceModalOpen = (isOpen: boolean) => {
    setIsDeviceModalOpen(isOpen);
  };

  useEffect(() => {
    return () => {
      if (isMeetingConnected) {
        handleDisconnectConsultation();
      }
    };
  }, [isMeetingConnected]);

  const meetingStatus = useMeetingStatus();

  const staticStyles = `
  display: flex;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  width: ${videoGridWidth};
  height: ${videoGridHeight};

  video {
    object-fit: contain;
  }
`;

  useEffect(() => {
    const updateVideoGridWidth = () => {
      if (videoGridRef.current) {
        const fullWidth = videoGridRef.current.offsetWidth || 0;
        const fullHeight = videoGridRef.current.offsetHeight || 0;
        setVideoGridWidth(`${fullWidth / 4}px`);
        setVideoGridHeight(`${fullHeight / 3}px`);
      }
    };

    // 会議室に接続したら幅を取得
    if (isMeetingConnected) {
      updateVideoGridWidth();

      // ウィンドウリサイズ時にも更新
      window.addEventListener("resize", updateVideoGridWidth);
      return () => {
        window.removeEventListener("resize", updateVideoGridWidth);
      };
    }
  }, [isMeetingConnected]);

  const { tiles } = useRemoteVideoTileState();

  useEffect(() => {
    console.log("現在のミーティングステータス:", meetingStatus);
  }, [meetingStatus]);

  // コンマ00秒になるまで待機
  const waitForExactMilliseconds = () => {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        const now = new Date();
        if (now.getMilliseconds() === 0) {
          clearInterval(interval);
          resolve();
        }
      }, 1); // 1ミリ秒ごとにチェック
    });
  };

  // Amazon Chimeミーティングへの接続処理
  const handleStartConsultation = async () => {
    console.log("ミーティング", "接続処理を開始します");
    if (!reservation) {
      console.error("予約情報が見つかりません。");
      return;
    }

    try {
      setIsLoadingConnect(true);

      const fetchData = async () => {
        const fetchOrCreateMeeting = async () => {
          // 00秒まで待機してから作成
          await waitForExactMilliseconds();
          await createMeeting(reservation.id);
          console.log("ミーティングを新規作成しました。");

          // 再取得
          const reFetchResponse = await getMeetingByReservationId(
            reservation.id,
          );
          console.log("再取得したミーティング情報:", reFetchResponse);
          return reFetchResponse.data as Response$get$MeetingByReservationId;
        };

        const res = await getMeetingByReservationId(reservation.id);

        if (!res.data || Object.keys(res.data).length === 0) {
          console.log("ミーティングデータが空のため、新規作成を開始します。");
          return fetchOrCreateMeeting();
        }

        const data = res.data as Response$get$MeetingByReservationId;

        if (!data.status) {
          console.log("ミーティングが閉じているため、新規作成を開始します。");
          return fetchOrCreateMeeting();
        }

        console.log("ミーティング情報を取得しました:", data);
        return data;
      };

      const data = (await fetchData()) as Response$get$MeetingByReservationId;

      if (!data) {
        console.error("データの取得に失敗しました。");
        return;
      }
      setIsLoadingConnect(true);

      const { meeting: meetingInfo, attendees: attendeesInfo } = data;

      const userAttendeeInfo = attendeesInfo.find((a) =>
        a.externalUserId.includes("Clinic"),
      );

      if (userAttendeeInfo === undefined) {
        console.error("ユーザーの情報が見つかりません。");
        return;
      }

      // ミーティング構成を設定
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        {
          MeetingId: meetingInfo.meetingId,
          MediaPlacement: {
            AudioHostUrl: meetingInfo.mediaPlacement.audioHostUrl,
            AudioFallbackUrl: meetingInfo.mediaPlacement.audioFallbackUrl,
            SignalingUrl: meetingInfo.mediaPlacement.signalingUrl,
            TurnControlUrl: meetingInfo.mediaPlacement.turnControlUrl,
            ScreenDataUrl: meetingInfo.mediaPlacement.screenDataUrl,
            ScreenViewingUrl: meetingInfo.mediaPlacement.screenViewingUrl,
            ScreenSharingUrl: meetingInfo.mediaPlacement.screenSharingUrl,
            EventIngestionUrl: meetingInfo.mediaPlacement.eventIngestionUrl,
          },
        },
        {
          AttendeeId: userAttendeeInfo.attendeeId,
          JoinToken: userAttendeeInfo.joinToken,
        },
      );

      console.log("ミーティングに参加します:", meetingSessionConfiguration);

      // ミーティングに参加
      await meetingManager.join(meetingSessionConfiguration);
      await meetingManager.start();

      if (!meetingManager.audioVideo) {
        console.error("MeetingManagerのaudioVideoが初期化されていません。");
        return;
      }

      setIsMeetingConnected(true);
    } catch (error) {
      console.error("ビデオ接続に失敗しました:", error);
    } finally {
      setIsLoadingConnect(false);
      setIsLoadingFetch(false);
      console.log("ミーティング", "接続処理が完了しました");
    }
  };

  const handleDisconnectConsultation = async () => {
    console.log("ミーティング", "切断処理を開始します");
    try {
      setIsLoadingConnect(true);

      if (meetingManager && meetingManager.audioVideo) {
        const audioVideo = meetingManager.audioVideo;

        // ビデオとオーディオを停止
        audioVideo.stopLocalVideoTile();
        audioVideo.stop();

        // ミーティングセッションから離脱
        await meetingManager.leave();
      }

      setIsMeetingConnected(false);
    } catch (error) {
      console.error("ビデオ切断に失敗しました:", error);
    } finally {
      setIsLoadingConnect(false);
      console.log("ミーティング", "切断処理が完了しました");
    }
  };

  useEffect(() => {
    getClinicReservationData();
    fetchClinic();
  }, [reservation_id]);

  // クリニック予約情報取得
  const getClinicReservationData = async () => {
    if (!reservation_id || !authFacilityStaff) return;
    try {
      const result = await showClinicReservation(reservation_id, {
        facility_id: authFacilityStaff.facilityId,
      });
      const userResult = await getUserDetail(result.data.userId);
      setUser(userResult.data);
      setReservation(result.data);

      if (result.data) {
        // 関連予約取得
        await fetchClinicRelateReservation(result.data.id);
        // 請求情報取得
        await fetchPayment(result.data.id);
        // 診察開始可能か判定
        HandleCanStartExamination(result.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbarProps("error", "予約情報の取得に失敗しました", true);
    }
  };

  // クリニック予約に紐づく関連予約の取得
  const fetchClinicRelateReservation = async (reservation_id: number) => {
    const result = await getClinicReservationRelation(reservation_id);
    const clinicRelateReservations = result.data.map(
      (record: ClinicReservationRelation) => {
        return {
          reservationId: record.reservationPharmacyId,
          reservationTime: record.reservationPharmacy.reservationTime,
          facilityName: facilityName(
            record.reservationPharmacy.facility.name,
            record.reservationPharmacy.facility.nameShort,
          ),
          facilityAddress: zipAndAddress(
            record.reservationPharmacy.facility.zip,
            record.reservationPharmacy.facility.address,
          ),
          tel: record.reservationPharmacy.facility.tel,
          fax: record.reservationPharmacy.facility.fax,
        } as RelateReservationData;
      },
    );
    console.log("関連予約情報", clinicRelateReservations);
    setRelationReservations(clinicRelateReservations);
  };

  // 請求情報取得
  const fetchPayment = async (reservation_id: number) => {
    const result = await getPaymentByReservation(reservation_id);
    if (result.data) {
      setPayment({
        id: result.data.id,
        amount: result.data.amount,
        status: result.data.status,
      } as PaymentData);
    }
  };

  // 施設情報取得（fincodeShopIdが設定済みか否かの判定用）
  const fetchClinic = async () => {
    if (!authFacilityStaff) return;

    const result = await getClinicDetail(String(authFacilityStaff.facilityId));
    if (
      result.data &&
      result.data.fincodeShopId &&
      result.data.fincodeShopId !== ""
    ) {
      setHasFincodeShopId(true);
    }
  };

  // 予約ステータスのUPDATE
  const reservationStatusUpdate = async (reservation_id: number) => {
    if (!reservation_id) return;
    const param = {
      status: ReservationStatus.Completed,
    };
    try {
      await updateReservationStatus(reservation_id, param);
      // 画面最新化
      await getClinicReservationData();
      setSnackbarProps("success", "予約ステータスが更新されました", true);
    } catch (error) {
      setSnackbarProps("error", "予約ステータスの更新に失敗しました", true);
    }
  };

  function HandleCanStartExamination(reservation: FacilityReservation) {
    if (reservation.isCanceled) {
      setReserveMessage("予約がキャンセルされています");
      setCanStartExamination(false);
      return;
    }

    // 現在の日付と時刻
    const now = dayjs();
    const reservationTime = dayjs(reservation.reservationTime); // 実際には予約日時を適切に設定する必要があります

    // 予約日時の日付
    const today = now.format("YYYY-MM-DD");
    const reservationDate = reservationTime.format("YYYY-MM-DD");

    // 時刻の比較 (30分前かどうか)
    const diffInMinutes = reservationTime.diff(now, "minute");

    if (now.isBefore(reservationTime)) {
      // 現在が予約日時より前
      if (today !== reservationDate) {
        setReserveMessage("予約日になるとボタンを押すことができます");
        setCanStartExamination(false);
      } else if (diffInMinutes > 29) {
        setReserveMessage("予約の30分前からボタンを押すことができます");
        setCanStartExamination(false);
      } else {
        setReserveMessage("");
        setCanStartExamination(true);
      }
    } else {
      // 現在が予約日時より後
      if (today !== reservationDate) {
        setReserveMessage("予約日を過ぎています。");
        setCanStartExamination(false);
      } else {
        setReserveMessage("");
        setCanStartExamination(true);
      }
    }
  }

  const formatUserAddress = (user: User | undefined) => {
    const hasAddress = user?.address1 || user?.address2 || user?.address3;
    if (!hasAddress) return "";
    let address: string = "";
    if (user.address1) address = address + user.address1;
    if (user.address2) address = address + user.address2;
    if (user.address3) address = address + user.address3;
    return address;
  };

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  // お薬手帳画面遷移
  const moveToMedicineNotebook = (reservationId: number) => {
    const url = `${reservationId}/medicine_notebook`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // 保険証画面遷移
  const moveToMedicineHicard = (reservationId: number) => {
    const url = `${reservationId}/hicard`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // 診療明細登録画面遷移
  const moveToReceiptDetailSending = (reservationId: number) => {
    const url = `/facility/reservation/receipt_detail/${reservationId}/send`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // 処方せん登録画面遷移
  const moveToPrescriptionSend = (
    reservationId: string,
    relationReservePharmacyId: string,
  ) => {
    const url = `/facility/reservation/prescription/${reservationId}/send?rel_reserve_id=${relationReservePharmacyId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // 血圧データ閲覧画面へ遷移
  const moveToSeeBloodPressure = (reservationId: number) => {
    const url = `${reservationId}/blood_pressure`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // 問診票回答閲覧画面へ遷移
  const moveToSeeMedicalQuestionResponse = (reservationId: number) => {
    const url = `${reservationId}/medical_response`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // 主訴情報閲覧画面へ遷移
  const moveToSeeIUserInfo = (reservationId: number) => {
    const url = `${reservationId}/user_info`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // 請求ボタン制御
  const paymentButtonDisabled =
    payAmount <= 0 || payment.id !== 0 || !hasFincodeShopId;

  // 請求ボタン下メッセージ
  const paymentMessage = () => {
    if (!hasFincodeShopId)
      return "現在、請求を確定できません。管理者にお問い合わせください。";
    if (payment.id !== 0) return "請求確定済です";
    if (payAmount <= 0) return "1以上の金額を入力してください";
    return "";
  };

  const processPayment = async (amount: number) => {
    try {
      const paymentResponse = await client.post(
        "payments/create_payment_link",
        { amount, facilityType: "clinic", reservationId: reservation?.id },
      );
      setPayment(paymentResponse.data);
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  // 支払い情報
  const paymentInfo = () => {
    return (
      <>
        <Typography
          sx={{ ...styles.TypographyPageTitle, mt: 8, textAlign: "left" }}
        >
          支払い情報
        </Typography>

        <Container maxWidth={false}>
          {/* 請求未確定時 */}
          {payment.id === 0 && (
            <>
              <Grid
                container
                sx={[
                  styles.gridContainer,
                  { marginTop: "0", paddingTop: "0", paddingBottom: 0 },
                ]}
                spacing={2}
              >
                <Grid
                  item
                  xs={9}
                  sx={[styles.gridItem, { paddingLeft: "5px" }]}
                >
                  <Typography sx={styles.boldLabel} marginLeft="1rem">
                    診察料
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={styles.gridItem}>
                  {null}
                </Grid>
                <Grid item xs={2} sx={styles.gridItem}>
                  <TextField
                    sx={styles.amountInput}
                    size={"small"}
                    value={payAmount}
                    type={"number"}
                    onChange={(e) => setPayAmount(Number(e.target.value))}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                sx={[
                  styles.gridContainer,
                  { marginTop: "0", paddingTop: "0", paddingBottom: 0 },
                ]}
                spacing={2}
              >
                <Grid
                  item
                  xs={9}
                  sx={[styles.gridItem, { paddingLeft: "5px" }]}
                >
                  <Typography sx={styles.boldLabel} marginLeft="1rem">
                    合計
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={styles.gridItem}>
                  {null}
                </Grid>
                <Grid item xs={2} sx={styles.gridItem}>
                  <Box
                    sx={styles.boldLabel}
                    margin="auto"
                  >{`${payAmount.toLocaleString()}円`}</Box>
                </Grid>
              </Grid>
            </>
          )}

          {/* 請求確定済時 */}
          {payment.id !== 0 && (
            <>
              <Grid
                container
                sx={[
                  styles.gridContainer,
                  { marginTop: "0", paddingTop: "0", paddingBottom: 0 },
                ]}
                spacing={2}
              >
                <Grid
                  item
                  xs={9}
                  sx={[styles.gridItem, { paddingLeft: "5px" }]}
                >
                  <Typography sx={styles.boldLabel} marginLeft="1rem">
                    診察料
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={styles.gridItem}>
                  {null}
                </Grid>
                <Grid item xs={2} sx={styles.gridItem}>
                  <Box
                    sx={styles.boldLabel}
                  >{`${payment.amount.toLocaleString()}円`}</Box>
                </Grid>
              </Grid>
              <Grid
                container
                sx={[
                  styles.gridContainer,
                  { marginTop: "0", paddingTop: "0", paddingBottom: 0 },
                ]}
                spacing={2}
              >
                <Grid
                  item
                  xs={9}
                  sx={[styles.gridItem, { paddingLeft: "5px" }]}
                >
                  <Typography sx={styles.boldLabel} marginLeft="1rem">
                    合計
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={styles.gridItem}>
                  {null}
                </Grid>
                <Grid item xs={2} sx={styles.gridItem}>
                  <Box
                    sx={styles.boldLabel}
                  >{`${payment.amount.toLocaleString()}円`}</Box>
                </Grid>
              </Grid>
            </>
          )}

          <Box sx={styles.ButtonContainer} alignItems="center">
            <Button
              sx={{ p: "0.7rem 5rem", width: "370px" }}
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => processPayment(payAmount)}
              disabled={paymentButtonDisabled}
            >
              請求を確定する
            </Button>
            {paymentButtonDisabled && (
              <FormHelperText error={true} sx={{ textAlign: "center" }}>
                {paymentMessage()}
              </FormHelperText>
            )}
          </Box>
        </Container>
      </>
    );
  };

  // 関連する予約の情報
  const relateReservationInfo = () => {
    if (relationReservations.length === 0) return;
    return (
      <>
        <Typography
          sx={{ ...styles.TypographyPageTitle, mt: 8, textAlign: "left" }}
        >
          関連する予約などの情報
        </Typography>
        <Container maxWidth={false}>
          {relationReservations.map((relationReservation) => (
            <Grid
              key={relationReservation.reservationId}
              container
              sx={[
                styles.gridContainer,
                { marginTop: "0", paddingTop: "0", paddingBottom: 0 },
              ]}
              spacing={2}
            >
              <Grid item xs={0} sx={[styles.gridItem, { paddingLeft: "5px" }]}>
                {null}
              </Grid>
              <Grid item xs={3} sx={styles.gridItem}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="0.5rem"
                  marginLeft="1rem"
                >
                  <Typography sx={styles.boldLabel}>予約日時</Typography>
                  <Typography sx={styles.boldLabel}>クリニック</Typography>
                  <Typography sx={styles.boldLabel}>所在地</Typography>
                  <Typography sx={styles.boldLabel}>TEL</Typography>
                  <Typography sx={styles.boldLabel}>FAX</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sx={styles.gridItem}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="0.5rem"
                  marginLeft="1rem"
                >
                  <Typography sx={styles.boldLabel}>
                    {formattedDatetime(relationReservation.reservationTime)}
                  </Typography>
                  <Typography sx={styles.boldLabel}>
                    {relationReservation.facilityName}
                  </Typography>
                  <Typography sx={styles.boldLabel}>
                    {relationReservation.facilityAddress}
                  </Typography>
                  <Typography sx={styles.boldLabel}>
                    {relationReservation.tel}
                  </Typography>
                  <Typography sx={styles.boldLabel}>
                    {relationReservation.fax}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sx={[styles.gridItem, { paddingLeft: "5px" }]}>
                <Box alignItems="baseline">
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "5px",
                      color: "#171C1F",
                      backgroundColor: "#C1C4EB",
                      "&:hover": {
                        backgroundColor: "#C1C4EB",
                      },
                    }}
                    onClick={() => {
                      reservation &&
                        moveToPrescriptionSend(
                          reservation.id.toString(),
                          relationReservation.reservationId.toString(),
                        );
                    }}
                  >
                    <Box
                      display="flex"
                      sx={{
                        alignItems: "center",
                        gap: "0.5rem",
                        m: "0.2rem 0",
                      }}
                    >
                      <PrescriptionIcon size={16} />
                      <Box sx={{ fontSize: "12px", color: "#171C1F" }}>
                        処方せんを送信
                      </Box>
                    </Box>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Container>
      </>
    );
  };

  // 予約ステータスChip
  const reservationStatusChip = (status: number) => {
    let label = "未完了";
    let style = styles.chipWarn;
    if (status === ReservationStatus.Completed) {
      label = "完了";
      style = styles.chipInfo;
    }
    return chipComponent(label, style);
  };

  // 請求ステータスChip
  const billingStatusChip = () => {
    let label = "未請求";
    let style = styles.chipWarn;

    if (payment.id > 0) {
      label = "請求済";
      style = styles.chipInfo;
    }
    return chipComponent(label, style);
  };

  // 決済ステータスChip
  const paymentStatusChip = () => {
    let label = "未決済";
    let style = styles.chipWarn;

    if (
      ["paid", "canceled", "price_changed"].includes(payment.status.toString())
    ) {
      label = "決済済";
      style = styles.chipInfo;
    }
    return chipComponent(label, style);
  };
  const SettlementStatusChip = () => (
    <Box display="flex" gap="1rem">
      {billingStatusChip()}
      {payment.id !== 0 && paymentStatusChip()}
    </Box>
  );
  return (
    <LayoutWithMenu mainContentsMaxWidth="md">
      <>
        <Typography
          sx={{
            ...styles.TypographyPageTitle,
            mt: 8,
            ml: 3,
            textAlign: "left",
          }}
        >
          予約情報
        </Typography>

        {!reservation && (
          <Container maxWidth={false} sx={{ mb: 3 }}>
            <FormHelperText error={true}>データがありません</FormHelperText>
          </Container>
        )}

        {reservation && (
          <>
            <Container maxWidth={false}>
              {gridItemWithLabel(
                "予約日時",
                formattedDatetime(reservation.reservationTime),
                ClockIcon,
              )}
              {gridItemWithLabel(
                "患者名",
                user?.name ? user?.name : "",
                PersonIcon,
              )}
              {gridItemWithLabel(
                "住所",
                zipAndAddress(user?.postalCode, formatUserAddress(user)),
                LocationIcon,
              )}
              {gridItemWithLabel("電話番号", user?.phone ?? "", PhoneIcon)}
              {gridItemWithLabel(
                "予約ステータス",
                reservationStatusChip(reservation.status),
                CalenderTodayIcon,
              )}
              {gridItemWithLabel(
                "決済ステータス",
                <SettlementStatusChip />,
                YenIcon,
              )}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(6, 1fr)",
                  gap: "16px",
                  m: "2rem 0",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px 4px",
                    borderRadius: "5px",
                    border: "1px solid #899296",
                    backgroundColor: "white",
                    "&:hover": {
                      border: "1px solid #899296",
                    },
                  }}
                  onClick={() => {
                    user && moveToMedicineNotebook(reservation.id);
                  }}
                >
                  <Box
                    display="flex"
                    sx={{ alignItems: "center", gap: "4px", m: "0.2rem 0" }}
                  >
                    <NotebookIcon size={16} />
                    <Box sx={{ fontSize: "12px", color: "#171C1F" }}>
                      お薬手帳を見る
                    </Box>
                  </Box>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px 4px",
                    borderRadius: "5px",
                    border: "1px solid #899296",
                    color: "#171C1F",
                    backgroundColor: "white",
                    "&:hover": {
                      border: "1px solid #899296",
                    },
                  }}
                  onClick={() => {
                    user && moveToMedicineHicard(reservation.id);
                  }}
                >
                  <Box
                    display="flex"
                    sx={{ alignItems: "center", gap: "4px", m: "0.2rem 0" }}
                  >
                    <CardIcon size={16} />
                    <Box sx={{ fontSize: "12px", color: "#171C1F" }}>
                      保険証を見る
                    </Box>
                  </Box>
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    padding: "8px 4px",
                    borderRadius: "5px",
                    color: "#171C1F",
                    backgroundColor: "#C1C4EB",
                    "&:hover": {
                      backgroundColor: "#C1C4EB",
                    },
                  }}
                  onClick={() => {
                    user && moveToReceiptDetailSending(reservation.id);
                  }}
                >
                  <Box
                    display="flex"
                    sx={{ alignItems: "center", gap: "4px", m: "0.2rem 0" }}
                  >
                    <CardIcon size={16} />
                    <Box sx={{ fontSize: "12px", color: "#171C1F" }}>
                      診療明細送信
                    </Box>
                  </Box>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px 2px",
                    borderRadius: "5px",
                    border: "1px solid #899296",
                    color: "#171C1F",
                    backgroundColor: "white",
                    "&:hover": {
                      border: "1px solid #899296",
                    },
                  }}
                  onClick={() => {
                    user && moveToSeeBloodPressure(reservation.id);
                  }}
                >
                  <Box
                    display="flex"
                    sx={{ alignItems: "center", gap: "4px", m: "0.2rem 0" }}
                  >
                    <BloodPressureIcon size={14} />
                    <Box sx={{ fontSize: "12px", color: "#171C1F" }}>
                      血圧データを見る
                    </Box>
                  </Box>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px 2px",
                    borderRadius: "5px",
                    border: "1px solid #899296",
                    color: "#171C1F",
                    backgroundColor: "white",
                    "&:hover": {
                      border: "1px solid #899296",
                    },
                  }}
                  onClick={() => {
                    user && moveToSeeMedicalQuestionResponse(reservation.id);
                  }}
                >
                  <Box
                    display="flex"
                    sx={{ alignItems: "center", gap: "4px", m: "0.2rem 0" }}
                  >
                    <FormIcon size={14} />
                    <Box sx={{ fontSize: "12px", color: "#171C1F" }}>
                      問診票回答を見る
                    </Box>
                  </Box>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px 4px",
                    borderRadius: "5px",
                    border: "1px solid #899296",
                    color: "#171C1F",
                    backgroundColor: "white",
                    "&:hover": {
                      border: "1px solid #899296",
                    },
                  }}
                  onClick={() => {
                    user && moveToSeeIUserInfo(reservation.id);
                  }}
                >
                  <Box
                    display="flex"
                    sx={{ alignItems: "center", gap: "4px", m: "0.2rem 0" }}
                  >
                    <MainInfoIcon size={16} />
                    <Box sx={{ fontSize: "12px", color: "#171C1F" }}>
                      主訴情報を見る
                    </Box>
                  </Box>
                </Button>
              </Box>
              {/* 予約が完了している場合はZoomへの連携ボタンを表示しない */}
              {reservation.status !== ReservationStatus.Completed && (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Button
                    sx={{ width: "370px" }}
                    color="primary"
                    variant="contained"
                    onClick={
                      isMeetingConnected
                        ? handleDisconnectConsultation
                        : handleStartConsultation
                    }
                    disabled={
                      !canStartExamination || isLoadingFetch || isLoadingConnect
                    }
                  >
                    {isMeetingConnected ? "診察を終わる" : "診察を始める"}
                  </Button>
                  <FormHelperText error={true}>{reserveMessage}</FormHelperText>
                </Box>
              )}
              {isMeetingConnected && (
                <ThemeProvider theme={lightTheme}>
                  <Box
                    borderRadius={"4px"}
                    sx={{
                      position: "relative",
                      width: "100%",
                      paddingTop: "56.25%", // 16:9アスペクト比
                      backgroundColor: "#000",
                      overflow: "hidden",
                      mb: 1,
                    }}
                  >
                    <Box
                      border={"1px solid #ddd"}
                      ref={videoGridRef}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <VideoGrid size={tiles.length} layout="standard">
                        {tiles.map((tileId) => (
                          <RemoteVideo
                            key={tileId}
                            tileId={tileId}
                            name={user?.name}
                          />
                        ))}
                        {tiles.length === 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                              bgcolor: "#000000",
                              color: "#ffffff",
                            }}
                          >
                            {/* タイルがない場合に表示するメッセージ */}
                            No Video
                          </Box>
                        )}
                      </VideoGrid>
                      <LocalVideo css={staticStyles} />
                    </Box>
                  </Box>
                  <MeetingVideoControlBox
                    isMeetingConnected={isMeetingConnected}
                    isVideoEnabled={isVideoEnabled}
                    isDeviceModalOpen={isDeviceModalOpen}
                    muted={muted}
                    toggleVideo={toggleVideo}
                    toggleMute={toggleMute}
                    handleDeviceModalOpen={handleDeviceModalOpen}
                    meetingManager={meetingManager}
                  />
                </ThemeProvider>
              )}
            </Container>

            {/* 支払い情報 */}
            {paymentInfo()}

            {/* 関連する予約の情報 */}
            {relateReservationInfo()}

            {/* 完了にするボタン */}
            {reservation?.status !== ReservationStatus.Completed && (
              <Box
                sx={{
                  display: "flex",
                  gap: "1.5rem",
                  m: "2rem 0",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{ p: "0.7rem 5rem", width: "370px" }}
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={() =>
                    reservationStatusUpdate(Number(reservation?.id))
                  }
                >
                  <Box
                    display="flex"
                    sx={{
                      alignItems: "center",
                      gap: "0.5rem",
                      m: "0.2rem 0",
                      color: "#171C1F",
                    }}
                  >
                    {ButtonCaption.completed}
                  </Box>
                </Button>
              </Box>
            )}
          </>
        )}

        {/* 戻るボタン */}
        <Box
          sx={{
            display: "flex",
            gap: "1.5rem",
            m: "2rem 0",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "5px",
              width: "370px",
              border: "1px solid #899296",
              backgroundColor: "white",
              "&:hover": {
                border: "1px solid #899296",
              },
            }}
            onClick={() => navigation("/facility/reservation")}
          >
            <Box
              display="flex"
              sx={{
                alignItems: "center",
                gap: "0.5rem",
                m: "0.2rem 0",
                color: "#171C1F",
              }}
            >
              {ButtonCaption.back}
            </Box>
          </Button>
        </Box>

        <Snackbar
          isOpen={snackbarOpen}
          setOpen={setSnackbarOpen}
          message={snackbarMessage}
          severity={severity}
        />
      </>
    </LayoutWithMenu>
  );
};

export default ClinicReservationDetail;

// 詳細項目Grid
const gridItemWithLabel = (
  label: string,
  value: React.ReactNode,
  IconComponent: React.ComponentType,
) => {
  return (
    <Grid
      container
      sx={[
        styles.gridContainer,
        { marginTop: "0", paddingTop: "0", paddingBottom: 0 },
      ]}
      spacing={2}
    >
      <Grid item xs={1} sx={[styles.gridItem, { paddingLeft: "5px" }]}>
        <Box component={IconComponent} sx={styles.icon} />
      </Grid>
      <Grid item xs={3} sx={styles.gridItem}>
        <Typography sx={styles.boldLabel}>{label}</Typography>
      </Grid>
      <Grid item xs={8} sx={styles.gridItem}>
        <Box sx={styles.boldLabel}>{value}</Box>
      </Grid>
    </Grid>
  );
};

const YenIcon = () => (
  <Box sx={{ pl: "3px" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        stroke="#32AFDA"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M9 11H15M9 14H15M12.004 11V17M15 7.5L12 10.5L9 7.5"
        stroke="#32AFDA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

// Chipオブジェクト
const chipComponent = (label: string, style: SxProps) => {
  return <Chip label={label} size="small" variant="filled" sx={style} />;
};
