import { Box, styled, Typography } from "@mui/material";
import LayoutWithMenu from "components/LayoutWithMenu";
import { styles } from "components/style/commonStyles";
import { ReserveInfo } from "./ReservedUserBloodPressure";

/** 主訴情報のデータ型 */
interface UserData {
  /** 所属する会社名 */
  companyName: string;
  /** お薬で副作用が出たことがあるか */
  hasSideEffects: string;
  /** 副作用が出たお薬の名前と症状 */
  sideEffectDetails: string;
  /** 食べ物などのアレルギーがあるか */
  hasAllergies: string;
  /** アレルギーが出る食物等と症状の内容 */
  allergyDetails: string;
  /** お酒を飲むか */
  drinksAlcohol: string;
  /** お酒の種類、頻度、飲む量 */
  alcoholDetails: string;
  /** タバコを吸うか */
  smokes: string;
  /** 喫煙年数、一日の本数 */
  smokingDetails: string;
  /** 車の運転や仕事で高所での作業等があるか */
  hasHighRiskWork: string;
}

// FIXME: API繋ぎこみ
const MEDICAL_QUESTION_RESPONSE_DATA: UserData = {
  companyName: "株式会社テスト",
  hasSideEffects: "ある",
  sideEffectDetails: "アスピリンで発疹が出た",
  hasAllergies: "ある",
  allergyDetails: "エビで呼吸困難",
  drinksAlcohol: "はい",
  alcoholDetails: "ビール、週3回、350ml缶1本",
  smokes: "はい",
  smokingDetails: "10年、1日10本",
  hasHighRiskWork: "ある",
};

// FIXME: API繋ぎこみ
const RESERVE_INFO: ReserveInfo = {
  name: "テスト患者ユーザー",
  reserveDate: new Date("2025-02-12T10:30:00"),
};

const Question = styled(Typography)(({ theme }) => ({
  ...styles.TypographyPageTitle,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(1),
  textAlign: "left",
}));

const Answer = styled(Box)({
  alignContent: "center",
  backgroundColor: "#F5FAFD",
  padding: "16px",
  borderBottom: "1px solid #ddd",
  fontSize: "15px",
  fontWeight: "bold",
});

const questionsAndAnswers = [
  {
    question: "所属する会社名をご記入ください",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.companyName,
  },
  {
    question: "お薬で副作用が出たことはありますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.hasSideEffects,
  },
  {
    question:
      "副作用が出たお薬の名前と症状を教えてください ※上記で「ある」と回答した方のみ",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.sideEffectDetails,
  },
  {
    question: "食べ物などのアレルギーはありますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.hasAllergies,
  },
  {
    question:
      "アレルギーが出る食物等と症状の内容を教えてください ※上記で「ある」と回答した方のみ",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.allergyDetails,
  },
  {
    question: "お酒は飲みますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.drinksAlcohol,
  },
  {
    question:
      "お酒の種類、頻度、飲む量を教えてください ※上記で「はい」と回答した方のみ",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.alcoholDetails,
  },
  {
    question: "タバコは吸いますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.smokes,
  },
  {
    question:
      "喫煙年数、一日の本数を教えてください ※上記で「ある」と回答した方のみ",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.smokingDetails,
  },
  {
    question: "車の運転や仕事で高所での作業等はありますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.hasHighRiskWork,
  },
];

export function ReservedUserInfo(): React.ReactElement {
  return (
    <LayoutWithMenu mainContentsMaxWidth="lg">
      <Box style={{ marginBottom: "40px" }}>
        <Typography
          sx={{
            ...styles.TypographyPageTitle,
            mt: 8,
            textAlign: "left",
          }}
        >
          主訴情報
        </Typography>
        <Typography
          sx={{
            ...styles.TypographyPageTitle,
            textAlign: "left",
            marginTop: 0,
            paddingBottom: 0,
          }}
        >
          {RESERVE_INFO.name}
        </Typography>
        <p>予約日時：{RESERVE_INFO.reserveDate.toLocaleString()}</p>
      </Box>
      <Box style={{ marginBottom: "40px" }}>
        {questionsAndAnswers.map((qa, index) => (
          <Box key={index}>
            <Question>{qa.question}</Question>
            <Answer>{qa.answer}</Answer>
          </Box>
        ))}
      </Box>
    </LayoutWithMenu>
  );
}
