import { IconProps } from "icon";

export const NotebookIcon = ({ size = 16 }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M2.25 6.00014C2.25 3.87914 2.25 2.81789 2.90925 2.15939C3.56775 1.50014 4.629 1.50014 6.75 1.50014H11.25C13.371 1.50014 14.4323 1.50014 15.0908 2.15939C15.75 2.81789 15.75 3.87914 15.75 6.00014V12.0001C15.75 14.1211 15.75 15.1824 15.0908 15.8409C14.4323 16.5001 13.371 16.5001 11.25 16.5001H6.75C4.629 16.5001 3.56775 16.5001 2.90925 15.8409C2.25 15.1824 2.25 14.1211 2.25 12.0001V6.00014Z"
      stroke="#003642"
      strokeWidth="1.5"
    />
    <path
      d="M6 1.87522V16.5002M1.5 9.00022H3M1.5 12.0002H3M1.5 6.00022H3M8.625 4.87522H12.375M8.625 7.50022H12.375"
      stroke="#003642"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
