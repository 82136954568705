/**
 * ISO8601形式→yyyy/mm/dd hh:mm 形式に変換する関数
 * @example "2025-01-21T22:29:26.000+09:00" → "2025/01/21 22:29"
 */
export function formatDateString(input: string) {
  const date = new Date(input); // ISO8601形式をパース
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 月は0から始まるため+1
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}`;
}
