import React from "react";
import { Box, IconButton, Drawer, Tab, Tabs, SxProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BasicSettingPanel } from "./Panel/BasicSettingPanel";
import { FirstPreferenceSettingsPanel } from "./Panel/FirstPreferenceSettingsPanel";
import { SecondPreferenceSettingsPanel } from "./Panel/SecondPreferenceSettingsPanel";

interface SettingSideBarProps {
  anchor: "left" | "right" | "top" | "bottom";
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
}

export function SettingSideBar(props: SettingSideBarProps): React.ReactElement {
  const { anchor, open, onClose, sx } = props;

  const toggleSidebar = () => {
    onClose();
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={toggleSidebar}
      sx={{ "& .MuiDrawer-paper": { width: "800px" }, ...sx }}
    >
      <Box sx={{ padding: 2, paddingBottom: 8, position: "relative" }}>
        <IconButton
          onClick={toggleSidebar}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            padding: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "sticky",
            top: 0,
            backgroundColor: "background.paper",
            zIndex: 100,
          }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab label="基本設定" {...a11yProps(0)} />
            <Tab label="優先設定①（日付）" {...a11yProps(1)} />
            <Tab label="優先設定②（期間）" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <BasicSettingPanel value={value} index={0} />
        <FirstPreferenceSettingsPanel value={value} index={1} />
        <SecondPreferenceSettingsPanel value={value} index={2} />
      </Box>
    </Drawer>
  );
}

function a11yProps(index: number) {
  return {
    id: `reservation-setting-${index}`,
    "aria-controls": `reservation-setting-panel-${index}`,
  };
}
