import { Box, styled, Typography } from "@mui/material";
import LayoutWithMenu from "components/LayoutWithMenu";
import { styles } from "components/style/commonStyles";
import { ReserveInfo } from "./ReservedUserBloodPressure";

/** 問診票フォームのデータ型 */
interface MedicalQuestionResponseData {
  /** 症状 */
  symptoms: string;
  /** 発症日 */
  onsetDate: string;
  /** 病歴 */
  medicalHistory: string;
  /** 服用中の薬があるか */
  hasCurrentMedications: string;
  /** 服用中の薬の名前 */
  currentMedicationsName: string;
  /** ジェネリック医薬品を希望するか */
  prefersGeneric: string;
  /** 妊娠中もしくは授乳中か */
  isPregnantOrBreastfeeding: string;
  /** 妊娠週数 */
  pregnancyWeeks: string;
  /** お薬手帳を持っているか */
  hasMedicationNotebook: string;
  /** その他 */
  otherDetails: string;
  /** 配送先の希望場所 */
  deliveryLocation: string;
}

// FIXME: API繋ぎこみ
const MEDICAL_QUESTION_RESPONSE_DATA: MedicalQuestionResponseData = {
  symptoms: "頭痛、吐き気",
  onsetDate: "2025-01-10",
  medicalHistory: "高血圧、糖尿病",
  hasCurrentMedications: "はい",
  currentMedicationsName: "ロキソニン",
  prefersGeneric: "はい",
  isPregnantOrBreastfeeding: "いいえ",
  pregnancyWeeks: "0",
  hasMedicationNotebook: "はい",
  otherDetails: "なし",
  deliveryLocation: "自宅",
};

// FIXME: API繋ぎこみ
const RESERVE_INFO: ReserveInfo = {
  name: "テスト患者ユーザー",
  reserveDate: new Date("2025-02-12T10:30:00"),
};

const Question = styled(Typography)(({ theme }) => ({
  ...styles.TypographyPageTitle,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(1),
  textAlign: "left",
}));

const Answer = styled(Box)({
  alignContent: "center",
  backgroundColor: "#F5FAFD",
  padding: "16px",
  borderBottom: "1px solid #ddd",
  fontSize: "15px",
  fontWeight: "bold",
});

const questionsAndAnswers = [
  {
    question: "本日はどのような症状で受診されましたか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.symptoms,
  },
  {
    question: "症状はいつから出ましたか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.onsetDate,
  },
  {
    question: "今までかかったことのある病気、または治療中の病気はありますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.medicalHistory,
  },
  {
    question: "現在服用中のお薬、サプリメントはございますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.hasCurrentMedications,
  },
  {
    question:
      "使用中のお薬やサプリメントの名前を記入してください ※上記で「ある」と回答した方のみ",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.currentMedicationsName,
  },
  {
    question: "ジェネリック医薬品を希望しますか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.prefersGeneric,
  },
  {
    question: "現在、妊娠中もしくは授乳中ですか？ ※女性のみ",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.isPregnantOrBreastfeeding,
  },
  {
    question:
      "妊娠週数を教えてください ※上記で「はい（妊娠中）」と回答した方のみ",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.pregnancyWeeks,
  },
  {
    question: "お薬手帳はお持ちですか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.hasMedicationNotebook,
  },
  { question: "その他", answer: MEDICAL_QUESTION_RESPONSE_DATA.otherDetails },
  {
    question: "お薬の配送先はどちらがご希望ですか？",
    answer: MEDICAL_QUESTION_RESPONSE_DATA.deliveryLocation,
  },
];

export function ReservedUserMedicalQuestionResponse(): React.ReactElement {
  return (
    <LayoutWithMenu mainContentsMaxWidth="lg">
      <Box style={{ marginBottom: "40px" }}>
        <Typography
          sx={{
            ...styles.TypographyPageTitle,
            mt: 8,
            textAlign: "left",
          }}
        >
          問診票回答
        </Typography>
        <Typography
          sx={{
            ...styles.TypographyPageTitle,
            textAlign: "left",
            marginTop: 0,
            paddingBottom: 0,
          }}
        >
          {RESERVE_INFO.name}
        </Typography>
        <p>予約日時：{RESERVE_INFO.reserveDate.toLocaleString()}</p>
      </Box>
      <Box style={{ marginBottom: "40px" }}>
        {questionsAndAnswers.map((qa, index) => (
          <Box key={index}>
            <Question>{qa.question}</Question>
            <Answer>{qa.answer}</Answer>
          </Box>
        ))}
      </Box>
    </LayoutWithMenu>
  );
}
