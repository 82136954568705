import React from "react";
import { Box, Button } from "@mui/material";

export function ReservationTimeline(): React.ReactElement {
  return (
    <Box
      sx={{
        flex: 1,
        pt: "8px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "66px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
          py: "1px",
        }}
      >
        <Button
          sx={{
            height: "32px",
            fontSize: "12px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block", // 必要に応じて追加
            width: "100%", // 必要に応じて親の幅に合わせる
          }}
        >
          xxxxxxxxx様 16時30分〜17時00分
        </Button>
      </Box>
      <Box
        sx={{
          height: "66px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
          py: "1px",
        }}
      >
        <Button
          sx={{
            height: "32px",
          }}
        >
          午前7時
        </Button>
      </Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#B3B3B380",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#B3B3B380",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>

      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#B3B3B380",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#B3B3B380",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#B3B3B380",
          borderTop: "1px solid #E0E0E0",
        }}
      ></Box>
    </Box>
  );
}
