import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import LayoutWithMenu from "components/LayoutWithMenu";
import { ReservationTimeline } from "./ReservationTimeline";

export function FacilityReservationCalendar(): React.ReactElement {
  // 午前7時から午後9時までの時間を生成
  const generateTimeSlots = (startHour: number, endHour: number) => {
    const timeSlots = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      const timeLabel =
        hour < 12
          ? `午前${hour}時`
          : hour === 12
          ? `午後${hour}時`
          : `午後${hour - 12}時`;
      timeSlots.push(timeLabel);
    }
    return timeSlots;
  };

  const timeSlots = generateTimeSlots(7, 21); // 午前7時から午後9時まで

  return (
    <LayoutWithMenu fullWidth>
      <Box sx={{ paddingTop: 2, mx: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
          <Stack
            sx={{
              position: "sticky", // スクロール時に固定
              left: 0, // 固定位置を上端から0pxに設定
              backgroundColor: "white", // 背景色を指定（スクロール時の視認性向上のため）
              zIndex: 1, // 他の要素より前面に配置
              padding: 2, // 任意の余白設定
            }}
          >
            <Typography
              fontWeight="bold"
              fontSize="20px"
              marginLeft="16px"
              marginBottom="16px"
            >
              予約カレンダー
            </Typography>
            <DateCalendar
              slotProps={{ calendarHeader: { format: "YYYY年MM月" } }}
              sx={{
                border: "1px solid #87D1EA",
              }}
            />
          </Stack>
          <Box
            sx={{
              border: "1px solid #87D1EA",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flex: 1,
            }}
          >
            {/* 時間スロット */}
            <Box
              sx={{
                width: "74px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {timeSlots.map((time, index) => (
                <Typography
                  key={index}
                  height={"64px"}
                  textAlign={"center"}
                >
                  {time}
                </Typography>
              ))}
            </Box>
            <Stack
              direction={"row"}
              spacing={2}
              width={"100%"}
              sx={{
                "& > :not(style) + :not(style)": {
                  position: "relative",
                  marginLeft: "8px", // spacingサイズと一致させる
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: "-8px", // spacingサイズと一致させる
                    width: "8px", // spacingサイズと一致させる
                    height: "100%",
                    backgroundColor: "#E0E0E0",
                    zIndex: -1,
                  },
                },
              }}
            >
              <ReservationTimeline />
              <ReservationTimeline />
              <ReservationTimeline />
              <ReservationTimeline />
              <ReservationTimeline />
            </Stack>
          </Box>
        </Box>
      </Box>
    </LayoutWithMenu>
  );
}
