import { Box, Button, Grid, Typography } from "@mui/material";
import { User } from "interfaces/index";
import React from "react";
import { ButtonCaption, FacilityType } from "../../utils/Constants";
import LayoutWithMenu from "../LayoutWithMenu";
import { AdminFacilityFormData } from "../schema/FacilitySchema";

import {
  AccessTime as ClockIcon,
  FaxOutlined as FaxIcon,
  LocalHospital as HospitalIcon,
  HouseOutlined as HouseIcon,
  Info as InfoIcon,
  Note as NoteIcon,
  Phone as PhoneIcon,
  LocalPostOfficeOutlined as PostIcon,
} from "@mui/icons-material";

// 各行に表示するアイコンのリストを作成
const iconsList = [
  HospitalIcon,
  PostIcon,
  HouseIcon,
  PhoneIcon,
  FaxIcon,
  ClockIcon,
  InfoIcon,
  NoteIcon,
];

const buttonStyle = {
  backgroundColor: "#21bddb",
  boxShadow: "none",
  fontSize: { xs: "0.75rem", sm: "0.875rem" },
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#1aa3c9",
    boxShadow: "none",
  },
  width: "200px",
  margin: "0",
};

const customStyles = {
  gridContainer: {
    borderBottom: "1px solid lightGrey",
    fontSize: 14,
    textDecoration: "none",
    color: "#000000",
    py: 1,
    backgroundColor: "#F5FAFD !important",
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "center", // 行の高さを揃えるために追加
    marginTop: 0,
    marginLeft: "0 !important",
  },
  gridItem: {
    alignContent: "center",
    backgroundColor: "#F5FAFD !important",
    display: "flex", // アイテムを中央揃えにするために追加
    alignItems: "center", // アイテムを中央揃えにするために追加
    paddingLeft: "0 !important",
    whiteSpace: "pre-line",
  },
  boldLabel: {
    fontWeight: 600, // 項目名を太字にする
  },
  container: {
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    textAlign: "center !important",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "0px",
    color: "#32AFDA",
    paddingLeft: "5px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10%", // ボタン間の間隔を設定
    width: "100%",
    marginTop: "30px",
  },
};

type Props = {
  facilityData: AdminFacilityFormData;
  moveToEdit: () => void;
  editable: boolean;
  adminAuth?: User | null;
  facilityType: string | undefined;
};

const FacilityDetailAdminContent: React.FC<Props> = (props) => {
  const { facilityData: data, moveToEdit, facilityType } = props;

  // FIXME: BEのAPI型定義修正後に下記削除し、dataをそのまま表示する
  const facilityData = {
    ...data,
    reservationPublishStatus: "公開中",
    reservationPublishDate: "2025/01/30",
  };

  // 詳細項目Grid
  const gridItemWithLabel = (
    label: string,
    value: string | JSX.Element | undefined,
    IconComponent: React.ComponentType,
  ) => {
    return (
      <Grid
        container
        sx={[customStyles.gridContainer, { marginTop: "0", paddingTop: "0" }]}
        spacing={2}
      >
        <Grid item xs={1} sx={[customStyles.gridItem, { paddingLeft: "5px" }]}>
          <Box component={IconComponent} sx={customStyles.icon} />
        </Grid>
        <Grid item xs={3} sx={customStyles.gridItem}>
          <Typography sx={customStyles.boldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={8} sx={customStyles.gridItem}>
          {value}
        </Grid>
      </Grid>
    );
  };

  return (
    <LayoutWithMenu pageTitle={"基本情報"}>
      {gridItemWithLabel("施設名称", facilityData.name, iconsList[0])}
      {gridItemWithLabel(
        "施設名称（略称）",
        facilityData.nameShort,
        iconsList[0],
      )}
      {gridItemWithLabel("施設名称（カナ）", facilityData.kana, iconsList[0])}
      {facilityType === FacilityType.clinic &&
        gridItemWithLabel("診療科", facilityData.department, iconsList[0])}
      {gridItemWithLabel("郵便番号", facilityData.zip, iconsList[1])}
      {gridItemWithLabel("住所", facilityData.address, iconsList[2])}
      {gridItemWithLabel("電話番号", facilityData.tel, iconsList[3])}
      {gridItemWithLabel("FAX番号", facilityData.fax, iconsList[4])}
      {gridItemWithLabel(
        "診療時間",
        facilityData.operatingHours ? facilityData.operatingHours : "情報なし",
        iconsList[5],
      )}
      {gridItemWithLabel("施設案内", facilityData.information, iconsList[6])}
      {gridItemWithLabel("備考", facilityData.note, iconsList[7])}
      {gridItemWithLabel(
        "予約公開ステータス",
        facilityData.reservationPublishStatus,
        iconsList[0],
      )}
      {gridItemWithLabel(
        "予約公開日",
        facilityData.reservationPublishDate,
        iconsList[0],
      )}
      {props.adminAuth &&
        gridItemWithLabel(
          "FincodeショップID",
          facilityData.fincodeShopId,
          iconsList[6],
        )}

      <Box sx={customStyles.buttonContainer}>
        <Button
          sx={buttonStyle}
          color="primary"
          variant="contained"
          onClick={moveToEdit}
        >
          {ButtonCaption.edit}
        </Button>
      </Box>
    </LayoutWithMenu>
  );
};

export default FacilityDetailAdminContent;
